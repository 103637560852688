
import * as d3 from "d3";

import "../../styles/components/teamViewSelector.css";

export class TeamViewSelector {

    /**
     * @protected
     * @type {(team_id: number) => void}
     */
    teamViewFunction;

    /**
     * @protected
     * @type {d3.Selection}
     */
    teamViewDivs;

    /**
     * @param {(team_id: number) => void} teamViewFunction  
     */
    constructor(teamViewFunction) {
        this.teamViewFunction = teamViewFunction;

        this.teamViewDivs = d3.selectAll(".scoreboard_team").select(".scoreboard_team_outerContent").append("div")
            .attr("class", "team_view_selector")
            .on("click", (_, d) => this.teamViewFunction(d.id));
    }

    destroy() {
        this.teamViewDivs.remove();
    }

}


export class TeamViewSelectorWithGeneralView extends TeamViewSelector {

    /**
     * @protected
     * @type {() => void}
     */
    generalViewFunction;

    /**
     * @protected
     * @type {d3.Selection}
     */
    generalViewDiv;

    /**
     * @param {(team_id: number) => void} teamViewFunction 
     * @param {() => void} generalViewFunction 
     */
    constructor(teamViewFunction, generalViewFunction) {
        super(teamViewFunction);

        this.generalViewFunction = generalViewFunction;

        this.generalViewDiv = d3.select("#host").select(".scoreboard_team_outerContent").append("div")
            .attr("class", "general_view_selector")
            .on("click", () => this.generalViewFunction());
    }

    destroy() {
        this.generalViewDiv.remove();

        super.destroy();
    }

}