import { PhaseButton } from "../components/phaseButton.js";
import { GUIObject } from "../guiObject.js";
import { Question } from "./question.js";
import * as d3 from "d3"

/**
 * @typedef {import("d3")} d3
 * @typedef {import("../guiManager.js").GUIManager} GUIManager
 */

/**
 * @enum {number}
 */
const PHASE = {
    STARTING: 1,
    QUESTION: 2,
    ESTIMATE: 3,
    RESULTS: 4,
    FINISHED: 5
};

/**
 * @enum {string}
 */
const MODE = {
    ABSOLUTE: "ABSOLUTE",
    PERCENTAGE: "PERCENTAGE"
}

/**
 * @enum {string}
 */
const NUMBERTYPE = {
    INTEGER: "INTEGER",
    FLOAT: "FLOAT"
}

/**
 * @type {Map<number,string>}
 */
const PHASE_NAMES = new Map([
    [1, "Start"],
    [2, "Frage"],
    [3, "Schätzen"],
    [4, "Ergebnisse"],
    [5, "Beendet"]
]);

const HTML = `
    <div id="question_estimate">
        <div id="question_estimate_content"></div>
        <div id="question_estimate_controls" class="hidden"></div>
    </div>
`;

export class Question_Estimate extends Question {

    /**
     * @type {PhaseButton}
     */
    phaseButton;
    

    constructor(guiManager, message) {
        super(guiManager, message);

        d3.select("#question_content").html(HTML);

        if (this.stateInfo.role == "admin") {
            this.controlDiv = d3.select("#question_estimate_controls")
                .classed("hidden", false);
            
            this.phaseButton = new PhaseButton(this, this.controlDiv, PHASE_NAMES, this.state.phase);
        }

        this.update();
    }

    destroy() {
        if (this.phaseButton) {
            this.phaseButton.remove();
        }
        if (this.resultButtons) {
            this.resultButtons.remove();
        }
        d3.select("#question_content").html("");
        super.destroy();
    }

    update() {
        super.update();

        if (this.phaseButton) {
            this.phaseButton.setPhase(this.state.phase);
        }
        this.questionDiv.classed("start", this.state.phase == PHASE.STARTING);
        if (this.stateInfo.role == "admin") {
            if (this.state.phase == PHASE.RESULTS) {
                if (this.resultButtons) {
                    this.resultButtons.remove();
                }
                
                for (let i = 0; i < this.state.num_questions; i++) {
                    this.controlDiv.append("div")
                        .attr("class", "resultButtons standardButton")
                        .html(i)
                        .on("click", () => {
                            let message = {
                                type: "show_result",
                                index: i
                            };
                            this.sendMessage(message);
                        });
                }
                this.resultButtons = this.controlDiv.selectAll(".resultButtons");
            } else if (this.state.phase == PHASE.FINISHED) {
                this.resultButtons.remove();
            }
        }
    }
}



export class Question_Estimation_Admin extends GUIObject {
    constructor(guiManager, message) {
        super(guiManager, message);

        this.div = d3.select("#question_estimate_content");
        
        this.update();
    }

    update() {
        this.div.selectAll(".question_estimate_question_only")
            .data(this.state.configs)
            .join("div")
            .attr("class", "question_estimate_question_only smallContentBox")
            .html(function(d) {
                let s = d.question + " - " + d.result + " [ " + d.mode + "; " + d.numbertype + " ]";
                if (d.admininfo) {
                    s += " " + d.admininfo;
                }
                return s;
            });
    }

    destroy() {
        this.div.html("");
    }
}

export class Question_Estimation_Input extends GUIObject {

    constructor(guiManager, message) {
        super(guiManager, message);

        if (this.stateInfo.role == "admin") {
            this.div = d3.selectAll(".scoreboard_team_content").filter((d, i) => i == this.state.teamid).append("div");
        } else {
            this.div = d3.select("#question_estimate_content").append("div")
                .attr("class", "question_estimate_question")
                .style("opacity", 0);
            this.div.transition().duration(2000).style("opacity", 100);
            this.div.append("div")
                .attr("class", "question_estimate_question_question smallContentBox")
                .html(this.state.question);
            let inputDiv = this.div.append("div")
                .attr("class", "question_estimate_question_input smallContentBox");
            this.input = inputDiv.append("input")
                .attr("type", "number")
            this.input.on("input", () => {
                    let value = this.input.property("value");
                    let parsedValue;
                    if (this.state.numbertype == NUMBERTYPE.INTEGER) {
                        parsedValue = parseInt(value);
                    } else {
                        parsedValue = parseFloat(value);
                    }
                    if (isNaN(parsedValue)) {
                        this.input.classed("invalid", true);
                        return;
                    } else {
                        this.input.classed("invalid", false);
                    }
                    let messageR = {
                        type: "new_answer",
                        answer: value
                    };
                    this.sendMessage(messageR);
                });
            if (this.state.numbertype == "INTEGER") {
                this.input.attr("step", 1);
            }
        }
        
        this.update();
    }

    update() {
        if (this.stateInfo.role == "admin") {
            this.div.html(this.state.answer);
        } else {
            this.input.property("value", this.state.answer)
                .classed("invalid", false);
        }
    }

    destroy() {
        this.div.remove();
    }
}

export class Question_Estimation_Result extends GUIObject {

    constructor(guiManager, message) {
        super(guiManager, message);

        let questionString = this.state.question;
        if (this.stateInfo.role == "admin") {
            questionString += " [ " + this.state.mode + "; " + this.state.numbertype + " ]";
        }
        if (this.state.admininfo) {
            questionString += " " + this.state.admininfo;
        }
        this.div = d3.select("#question_estimate_content").append("div")
            .attr("class", "question_estimate_question_result");
        this.div.append("div")
            .attr("class", "question_estimate_question_only smallContentBox")
            .html(questionString);
        this.resultDiv = this.div.append("div")
            .attr("class", "question_estimate_question_only smallContentBox");
        this.resultresultDiv = this.resultDiv.append("div")
            .classed("invisible", !(this.stateInfo.role == "admin" || this.state.result_visible))
            .classed("halfvisible", (this.stateInfo.role == "admin" && !this.state.result_visible))
            .html(this.state.result);
        
        this.teamDivs = d3.selectAll(".scoreboard_team_content").append("div")
            .attr("class", "question_estimate_scoreboard contentBox")
            .data(this.state.estimations);
        this.teamDivs.append("div")
            .attr("class", "question_estimate_scoreboard_value")
            .classed("invisible", d => !(this.stateInfo.role == "admin" || d.value_visible))
            .classed("halfvisible", d => (this.stateInfo.role == "admin" && !d.value_visible))
            .html(d => d.value);
        this.teamDivs.append("div")
            .attr("class", "question_estimate_scoreboard_accuracy")
            .classed("invisible", d => !(this.stateInfo.role == "admin" || d.accuracy_visible))
            .classed("halfvisible", d => (this.stateInfo.role == "admin" && !d.accuracy_visible))
            .html(d => d.accuracy);

        if (this.stateInfo.role == "admin") {
            this.resultDiv.append("div")
                .attr("class", "standardButton")
                .html("Show")
                .on("click", () => {
                    let message = {
                        type: "show_result"
                    };
                    this.sendMessage(message);
                });
            this.teamDivs.append("div")
                .attr("class", "standardButton")
                .html("Show")
                .on("click", (_, d) => {
                    let message = {
                        type: "show_estimation",
                        teamid: d.teamid
                    };
                    this.sendMessage(message);
                });
        }
    }

    update() {
        this.teamDivs.data(this.state.estimations)
            .join(() => {}, (update) => {
                update.classed("winner", d => d.winner);
                update.select(".question_estimate_scoreboard_value")
                    .classed("invisible", d => !(this.stateInfo.role == "admin" || d.value_visible))
                    .classed("halfvisible", d => (this.stateInfo.role == "admin" && !d.value_visible))
                    .html(d => d.value);
                update.select(".question_estimate_scoreboard_accuracy")
                    .classed("invisible", d => !(this.stateInfo.role == "admin" || d.accuracy_visible))
                    .classed("halfvisible", d => (this.stateInfo.role == "admin" && !d.accuracy_visible))
                    .html(d => d.accuracy);
            });
        this.resultresultDiv
            .classed("invisible", !(this.stateInfo.role == "admin" || this.state.result_visible))
            .classed("halfvisible", (this.stateInfo.role == "admin" && !this.state.result_visible))
    }

    destroy() {
        this.div.remove();
        this.teamDivs.remove();
    }
}