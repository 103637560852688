
import { GUIManager } from "../guiManager.js";
import { GUIObject } from "../guiObject.js";
import { createRoot } from "react-dom/client";
import * as d3 from "d3";

import "../../styles/questions/question.css";
import { CHexagonBox } from "../components/contentBox.js";


/**
 * @template {Object} S
 * @template {Object} SI
 * @extends GUIObject<S,SI>
 */
export class Question extends GUIObject {

    /**
     * @type {(state : S) => boolean}
     */
    startPositionFunction;
    
    /**
     * @param {GUIManager} guiManager 
     * @param {Object} message 
     * @param {(state : S) => boolean} startPosition 
     */
    constructor(guiManager, message, startPositionFunction = () => false) {
        super(guiManager, message);

        this.startPositionFunction = startPositionFunction;

        d3.select("#quiz_content").html(HTML);

        this.questionDiv = d3.select("#question");
        this.questionContentDiv = this.questionDiv.select("#question_content");
    }

    destroy() {
        this.questionDiv.remove();
        super.destroy();
    }

    update() {
        super.update();
        this.startPosition(this.startPositionFunction(this.state));
    }

    /**
     * @param {string} url 
     */
    setBackgroundImage(url) {
        this.questionDiv.style("background-image", "url(" + url + ")");
    }

    /**
     * @param {boolean} b 
     * @protected
     */
    startPosition(b) {
        this.questionDiv.classed("start", b);
    }

    handleMessage(message) {
        switch (message.all.type) {
            default:
                break;
        }
    }
}

const HTML = `
    <div id="question">
        <div id="question_header"></div>
        <div id="question_content"></div>
    </div>
`;


/**
 * @template {import("../definitions/questions/question.defs.js").State} S
 * @template {import("../definitions/questions/question.defs.js").StateInfo} SI
 * @extends GUIObject<S,SI>
 */
export class QuestionHeader extends GUIObject {

    /**
     * @type {Question}
     */
    question;

    /**
     * @type {import("react-dom/client").Root}
     */
    root;

    /**
     * @type {React.JSX.Element}
     */
    timer;
    
    /**
     * @param {GUIManager} guiManager 
     * @param {Object} message 
     */
    constructor(guiManager, message) {
        super(guiManager, message);

        this.question = this.guiManager.getGuiObjectByType(Question);
        this.question.setBackgroundImage("./content/backgroundimages/" + this.state.question_info.background_image);

        let rootElement = document.getElementById("question_header");
        this.root = createRoot(rootElement);
    }

    destroy() {
        this.root.unmount();
        this.root = null;
        super.destroy();
    }

    update() {
        super.update();

        const subquestionText = this.state.num_subquestions > 1 ? this.state.current_subquestion + " / " + this.state.num_subquestions : null;

        if (!this.root) return;
        this.root.render(<>
            <CTitleBox
                title={this.state.question_info.title}
                subtitle={this.state.question_info.subtitle}
                secrettitle={this.state.question_info.secrettitle}
                type={this.state.question_info.type}
                category={this.state.question_info.category}
                info={this.state.question_info.info}
                subquestionText={subquestionText}
                scoreText={this.state.score_info}
            />
            <CHeaderTimer timer={this.timer} />
        </>);
    }

    /**
     * @param {React.JSX.Element} timer
     */
    updateTimer(timer) {
        this.timer = timer;
        this.update();
    }

    handleMessage(message) {
        switch (message.all.type) {
            default:
                break;
        }
    }
}

/**
 * @param {Object} props 
 * @param {string} props.title
 * @param {string} [props.subtitle]
 * @param {string} props.secrettitle
 * @param {string} props.type
 * @param {string} props.category
 * @param {import("../definitions/elements/info.defs.js").Info} [props.info]
 * @param {string} [props.subquestionText]
 * @param {string} props.scoreText
 * @returns {React.JSX.Element}
 */
function CTitleBox({title, subtitle, secrettitle, type, category, info, subquestionText, scoreText}) {
    const typeClasses = "question_header_infos_type question_type noBackground " + type;
    const categoryClasses = "question_header_infos_category question_category noBackground " + category;

    return (
        <div className="question_header_titleBox_wrapper">
            <div className="question_header_titleBox">
                <CHexagonBox deg60={true} info={info}>
                    <div className="question_header_titleBox_inner">
                        <div className="question_header_infos_top">
                            <div className="question_header_infos_secrettitle">{secrettitle}</div>
                            <div className={typeClasses}></div>
                            <div className={categoryClasses}></div>
                        </div>
                        <div className="question_header_title">{title}</div>
                        <div className="question_header_infos_bottom">
                            {subquestionText && <div className="question_header_subquestion">{subquestionText}</div>}
                            <div className="question_header_subtitle">{subtitle ? subtitle : ""}</div>
                            <div className="question_header_score">{scoreText}</div>
                        </div>
                    </div>
                </CHexagonBox>
            </div>
        </div>
        
    );
}

/**
 * @param {Object} props 
 * @param {React.JSX.Element} props.timer
 * @returns {React.JSX.Element}
 */
function CHeaderTimer({timer}) {
    return (
        <div className="question_header_timer">
            <CHexagonBox deg60={true}>
                <div className="question_header_timer_inner">
                    {timer}
                </div>
            </CHexagonBox>
        </div>
    );
}