
import "../../styles/components/hoverSelectorList.css";

/**
 * @param {Object} props
 * @param {Object[]} props.selectors
 * @param {string} props.selectors[].text
 * @param {() => void} props.selectors[].onClick
 * @param {boolean} [props.fadeDown]
 * @returns {React.JSX.Element} 
 */
export function CHoverSelectorList({selectors, fadeDown=false}) {
    const selectorDivs = selectors.map(s => (
        <div key={s.text} className="selector" onClick={s.onClick}>
            {s.text}
        </div>
    ));
    let classes = "selector_list";
    if (fadeDown) classes += " fadeDown";
    return (
        <div className={classes}>
            <div className="selector_list_inner">
                {selectorDivs}
            </div>
        </div>
    );
}