import { GUIManager } from "../guiManager.js";
import { GUIObject } from "../guiObject.js";
import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { ROLE, MESSAGE, CLASSID } from "../definitions/elements/timer.defs.js";

import "../../styles/elements/timer.css"
import { QuestionHeader } from "../questions/question.js";


/**
 * classid {@link CLASSID}
 * @extends GUIObject<import("../definitions/elements/timer.defs.js").State,import("../definitions/elements/timer.defs.js").StateInfo>
 */
export class Timer extends GUIObject {

    /**
     * @type {import("react-dom/client").Root}
     * @protected
     */
    root;

    /**
     * @type {QuestionHeader}
     */
    parentObject;

    /**
     * Whether to use the simple layout.
     * @constant
     * @type {boolean}
     * @private
     */
    simple;

    /**
     * Whether to use the horizontal layout.
     * @constant
     * @type {boolean}
     * @private
     */
    horizontal;


    /**
     * @param {GUIManager} guiManager 
     * @param {Object} message 
     * @param {string} selector 
     */
    constructor(guiManager, message, selectorId="timer", simple=false, horizontal=false) {
        super(guiManager, message);

        this.simple = simple;
        this.horizontal = horizontal;

        if (selectorId === "timer") {
            this.parentObject = this.guiManager.getGuiObjectByType(QuestionHeader);
        } else {
            let domElement = document.getElementById(selectorId);
            this.root = createRoot(domElement);
        }
    }

    update() {
        super.update();
        
        const handelPlay = () => this.sendMessage({
            type: MESSAGE.PLAY
        });
        const handelPause = () => this.sendMessage({
            type: MESSAGE.PAUSE
        });
        const handelReset = () => this.sendMessage({
            type: MESSAGE.RESET
        });

        const component = 
            <CTimer 
                simple={this.simple}
                horizontal={this.horizontal}
                dateAtUpdate={Date.now()}
                currentSecondsAtUpdate={this.state.time_current} 
                fullSeconds={this.state.time_full} 
                interims={this.state.interims}
                paused={this.state.paused}
                isModerator={this.stateInfo.role == ROLE.ADMIN}
                onPlay={handelPlay}
                onPause={handelPause}
                onReset={handelReset}
            />;

        if (this.root) {
            this.root.render(component);
        } else {
            this.parentObject.updateTimer(component);
        }
    }

    destroy() {
        if (this.root) {
            this.root.unmount();
        } else {
            this.parentObject.updateTimer(null);
        }

        super.destroy();
    }
}


/**
 * @param {Object} props 
 * @param {boolean} props.simple simple layout
 * @param {boolean} props.horizontal horizontal progress bar
 * @param {number} props.dateAtUpdate date at last state update
 * @param {number} props.currentSecondsAtUpdate current seconds at update
 * @param {number} props.fullSeconds max timer seconds
 * @param {Object[]} props.interims
 * @param {number} props.interims[].time
 * @param {string} props.interims[].name
 * @param {boolean} props.paused
 * @param {boolean} props.isModerator
 * @param {() => void} props.onPlay
 * @param {() => void} props.onPause
 * @param {() => void} props.onReset
 * @returns {React.JSX.Element}
 */
function CTimer({simple, horizontal, dateAtUpdate, currentSecondsAtUpdate, fullSeconds, interims, paused, isModerator, onPlay, onPause, onReset}) {

    const [displaySeconds, setDisplaySeconds] = useState(Math.floor(currentSecondsAtUpdate));

    useEffect(() => {
        setDisplaySeconds(Math.floor(currentSecondsAtUpdate));
    }, [currentSecondsAtUpdate, paused]);

    useEffect(() => {
        if (displaySeconds <= 0) return;
        if (paused) {
            setDisplaySeconds(Math.floor(currentSecondsAtUpdate));
            return;
        }
        
        const timeoutMillis = (currentSecondsAtUpdate - displaySeconds) * 1000 + dateAtUpdate - Date.now();
        const timeout = setTimeout(() => {
            setDisplaySeconds(time => time - 1);
        }, timeoutMillis);

        return () => clearTimeout(timeout);
    }, [dateAtUpdate, displaySeconds, paused]);

    let timerClasses = "timer";
    if (simple) timerClasses += " simple";
    if (horizontal) timerClasses += " horizontal";
    return (
        <div className={timerClasses}>
            <CTimerProgressBar horizontal={horizontal} displaySeconds={displaySeconds} fullSeconds={fullSeconds} />
            {!simple && <CTimerInterims interims={interims} isModerator={isModerator} />}
            {!simple && <CTimerLabel displaySeconds={displaySeconds} />}
            {!simple && <CTimerControls paused={paused} isModerator={isModerator} onPlay={onPlay} onPause={onPause} onReset={onReset} />}
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {boolean} props.horizontal
 * @param {number} props.displaySeconds
 * @param {number} props.fullSeconds
 * @returns {React.JSX.Element}
 */
function CTimerProgressBar({horizontal, displaySeconds, fullSeconds}) {
    const pathLength = horizontal ? 90 : 283;
    const pathFraction = ((displaySeconds / fullSeconds) * pathLength) + " " + pathLength;
    const pathStateClass = 
        displaySeconds > 10 ? "safe" : 
        displaySeconds > 5 ? "warning" : 
        displaySeconds > 0 ? "alert" : 
        "over";
    const pathFullClasses = "timer_svg_path-remaining " + pathStateClass;

    return (
        <svg className="timer_svg" viewBox={horizontal  ? "0 0 100 10" : "0 0 100 100"}>
            <g className="timer_g">
                <circle className="timer_svg_path-elapsed" cx="50" cy="50" r="45"></circle>
                <path
                    strokeDasharray={pathFraction}
                    strokeDashoffset={horizontal ? -(pathLength - pathFraction) / 2 : null}
                    className={pathFullClasses}
                    d={horizontal ? "M 5, 5 l 90, 0" : "M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0"}
                ></path>
            </g>
        </svg>
    );
}

/**
 * @param {Object} props 
 * @param {boolean} props.isModerator
 * @param {Object[]} props.interims
 * @param {number} props.interims[].time
 * @param {string} props.interims[].name
 * @returns {React.JSX.Element}
 */
function CTimerInterims({interims, isModerator}) {
    if (!isModerator) return null;
    const interimItems = interims.map(i => {
        let seconds = Math.floor(i.time);
        let milliseconds = Math.floor((i.time-seconds) * 1000);
        return <div className={i.time < 0 ? "late" : ""} key={i.name}>{seconds}s {milliseconds}ms</div>
    });
    return (
        <div className="timer_interims">
            {interimItems}
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.displaySeconds
 * @returns {React.JSX.Element}
 */
function CTimerLabel({displaySeconds}) {
    return (
        <div className="timer_label">{Math.max(displaySeconds, 0)}</div>
    );
}

/**
 * @param {Object} props 
 * @param {boolean} props.paused
 * @param {boolean} props.isModerator
 * @param {() => void} props.onPlay
 * @param {() => void} props.onPause
 * @param {() => void} props.onReset
 * @returns {React.JSX.Element}
 */
function CTimerControls({paused, isModerator, onPlay, onPause, onReset}) {
    if (!isModerator) return null;
    return (
        <div className="timer_controls">
            <div className={paused ? "" : "hidden"} onClick={() => onPlay()}>⏵</div>
            <div className={paused ? "hidden" : ""} onClick={() => onPause()}>⏸</div>
            <div onClick={() => onReset()}>⏹</div>
        </div>
    );
}