
import { CSSTransition, TransitionGroup } from 'react-transition-group';


const MAX_TIMEOUT = 2000; // TODO


/**
 * @param {Object} props 
 * @param {{jsx: React.JSX.Element, ref: React.MutableRefObject, key: React.Key}[]} props.elements
 * @param {"animationend" | "transitionend"} props.endType
 * @param {string} [props.exitClassName]
 * @returns {React.JSX.Element}
 */
export function CExitList({elements, endType, exitClassName="remove", maxTimeout = MAX_TIMEOUT}) {
    const classNames = {
        appear: "",
        appearActive: "",
        appearDone: "",
        enter: "",
        enterActive: "",
        enterDone: "",
        exit: exitClassName,
        exitActive: "",
        exitDone: "",
    };
    const children = elements.map(element => {
        const endListener = done => {
            element.ref.current.addEventListener(endType, e => {
                if (e.target !== e.currentTarget) return;
                done(e);
            });
        };
        return (
            <CSSTransition key={element.key} nodeRef={element.ref} unmountOnExit addEndListener={endListener} timeout={maxTimeout} classNames={classNames}>
                {element.jsx}
            </CSSTransition>
        );
    });
    return (
        <TransitionGroup component={null} >
            {children}
        </TransitionGroup>
    );
}
