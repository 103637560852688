import { useEffect, useRef, useState } from "react";
import { CHoverInfo } from "../elements/hoverInfo.js";

import "../../styles/components/contentBox.css";


const MARGIN_FACTOR = 1.5;
const PADDING_EM_INNER = 0.2;

/**
 * @param {Object} props 
 * @param {React.JSX.Element} props.children
 * @param {number} [props.deg60]
 * @param {number} [props.leftInnerEdge]
 * @param {number} [props.rightInnerEdge]
 * @param {import("../definitions/elements/info.defs").Info} [props.info]
 * @param {React.JSX.Element} [props.bottomChildren]
 * @returns {React.JSX.Element}
 */
export function CHexagonBox({children, deg60=false, leftInnerEdge=false, rightInnerEdge=false, info, bottomChildren}) {
    const [refOuter, heightOuter] = useHeightObserver();
    const [refInner, heightInner] = useHeightObserver();

    const relWidth = deg60 ? Math.tan(Math.PI / 3) : Math.tan(Math.PI / 4);
    const widthOuter = heightOuter / relWidth / 2;
    const widthInner = heightInner / relWidth / 2;

    const relBorder = deg60 ? Math.sin(Math.PI / 3) : Math.sin(Math.PI / 4);

    const leftOuterVertices = leftInnerEdge ? 
        `0 0, ${widthOuter}px 50%, 0 100%` : 
        `${widthOuter}px 0, 0 50%, ${widthOuter}px 100%`;
    const rightOuterVertices = rightInnerEdge ? 
        `100% 100%, calc( 100% - ${widthOuter}px ) 50%, 100% 0` : 
        `calc( 100% - ${widthOuter}px ) 100%, 100% 50%, calc( 100% - ${widthOuter}px ) 0`;
    const leftInnerVertices = leftInnerEdge ? 
        `0 0, ${widthInner}px 50%, 0 100%` : 
        `${widthInner}px 0, 0 50%, ${widthInner}px 100%`;
    const rightInnerVertices = rightInnerEdge ? 
        `100% 100%, calc( 100% - ${widthInner}px ) 50%, 100% 0` : 
        `calc( 100% - ${widthInner}px ) 100%, 100% 50%, calc( 100% - ${widthInner}px ) 0`;

    const clipPathOuter = "polygon( " + leftOuterVertices + ", " + rightOuterVertices + " )";
    const clipPathInner = "polygon( " + leftInnerVertices + ", " + rightInnerVertices + " )";

    const paddingLeftOuter = `calc( var(--_border-width-hexagon-box) / ${relBorder}
        ${leftInnerEdge ? ` + var(--_border-width-hexagon-box) / ${relWidth}` : ""} )`;
    const paddingRightOuter = `calc( var(--_border-width-hexagon-box) / ${relBorder}
        ${rightInnerEdge ? ` + var(--_border-width-hexagon-box) / ${relWidth}` : ""} )`;
    const paddingLeftInner = leftInnerEdge ? `calc( ${PADDING_EM_INNER}em + ${widthInner}px )` : widthInner;
    const paddingRightInner = rightInnerEdge ? `calc( ${PADDING_EM_INNER}em + ${widthInner}px )` : widthInner;

    /** @type {React.CSSProperties} */
    const styleOuter = {clipPath: clipPathOuter, paddingLeft: paddingLeftOuter, paddingRight: paddingRightOuter};
    /** @type {React.CSSProperties} */
    const styleInner = {clipPath: clipPathInner, paddingLeft: paddingLeftInner, paddingRight: paddingRightInner};

    return (
        <div className="hexagon_box_wrapper" style={{marginLeft: leftInnerEdge ? -widthOuter / MARGIN_FACTOR : 0}}>
            <div className="hexagon_box_bottom" style={{width: `calc( 100% - ${2 * widthOuter}px )`, left: widthOuter}}>
                {info && <CHoverInfo info={info} fadeDown={true} />}
                {bottomChildren}
            </div>
            <div ref={refOuter} className="hexagon_box_outer" style={styleOuter}>
                <div ref={refInner} className="hexagon_box_inner" style={styleInner}>
                    {children}
                </div>
            </div>
        </div>
    );
}


/**
 * @returns {[React.MutableRefObject, number]} 
 */
function useHeightObserver() {
    const [height, setHeight] = useState(0);
    const observedElementRef = useRef(null);

    useEffect(() => {
        if (!observedElementRef.current) return;
        const observer = new ResizeObserver(entries => {
            setHeight(entries[0].borderBoxSize[0].blockSize);
        });
        observer.observe(observedElementRef.current);
        return () => observer.disconnect();
    }, []);

    return [observedElementRef, height];
}