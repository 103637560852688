/**
 * TODOs
 * left right arrow for fullscreen images
 * fullscreen image controls for moderators
 */

import { CLASSID, MESSAGE, PHASE, SETTINGS, TAG } from "shared/definitions/questions/sort.defs.js";
import { TeamViewSelectorWithGeneralView } from "../components/teamViewSelector.js";
import { Question } from "./question.js";
import { createRoot } from "react-dom/client";
import React, { createContext, createRef, forwardRef, useCallback, useContext, useEffect, useRef, useState } from "react";
import { CControlButtonMini, CQuestionContentControls, CQuestionContentControlsButton, CQuestionContentControlsPhaseButton } from "../components/contentControls.js";
import { getLanguageText } from "../language.js";
import { CHexagonBox } from "../components/contentBox.js";
import { CExitList } from "../components/exitElement.js";
import { CHoverSelectorList } from "../components/hoverSelectorList.js";

import "../../styles/questions/sort.css";


/**
 * Lookup table for the display text of a tag.
 * @type {Map<NOTE_TAG,string>}
 */
const TAG_TEXT_LOOKUP = new Map([
    [TAG.NONE, ""],
    [TAG.SURE, "✓"],
    [TAG.UNSURE, "?"]
]);

/**
 * Lookup table for the css class of a tag.
 * @type {Map<NOTE_TAG,string>}
 */
const TAG_CLASS_LOOKUP = new Map([
    [TAG.NONE, ""],
    [TAG.SURE, " sure"],
    [TAG.UNSURE, " unsure"]
]);

const TIME_CORRECT_ANSWER = 5000;
const TIME_NOTE_CHANGED = 1000;

const START_POSITION_FUNCTION = state => state.phase === PHASE.START;

/**
 * classid {@link CLASSID}
 * @extends Question<import("shared/definitions/questions/sort.defs").State,import("shared/definitions/questions/sort.defs").StateInfo>
 */
export class Question_Sort extends Question {

    /**
     * @type {import("react-dom/client").Root}
     */
    root;

    /**
     * @type {TeamViewSelectorWithGeneralView}
     */
    teamViewSelector;

    /**
     * @type {number}
     */
    activeTeamView;


    /**
     * @param {GUIManager} guiManager 
     * @param {*} message 
     */
    constructor(guiManager, message) {
        super(guiManager, message, START_POSITION_FUNCTION);
        
        const element = document.getElementById("question_content");
        this.root = createRoot(element);

        if (this.stateInfo.role.isAdmin) {
            if (this.stateInfo.role.isModerator) {
                this.teamViewSelector = new TeamViewSelectorWithGeneralView(
                    id => this.sendMessage({
                        type: MESSAGE.MODERATED_TEAM,
                        team_id: id
                    }),
                    () => this.sendMessage({
                        type: MESSAGE.MODERATED_TEAM,
                        team_id: -1
                    })
                );
            } else {
                this.teamViewSelector = new TeamViewSelectorWithGeneralView(
                    id => {
                        this.activeTeamView = id;
                        this.update();
                    },
                    () => {
                        this.activeTeamView = -1;
                        this.update();
                    }
                );
            }
        }

        this.activeTeamView = -1;
        if (this.state.teams.length === 1) {
            this.activeTeamView = this.state.teams[0].team_id;
        }
    }

    destroy() {
        if (this.teamViewSelector) this.teamViewSelector.destroy();
        this.root.unmount();
        super.destroy();
    }

    update() {
        super.update();

        const activeTeamView = this.stateInfo.isModerator || this.activeTeamView < 0 ? 
            this.state.moderation_team_id : this.activeTeamView;

        this.root.render(
            <CQuestionSort 
                state={this.state}
                stateInfo={this.stateInfo}
                activeTeamView={activeTeamView}
                sendMessage={this.sendMessage.bind(this)}
            />
        );
    }
}

/**
 * @param {number} id 
 * @param {(boolean) => void} setPositioning 
 */
function getHandleDragStart(id, setPositioning) {
    return /** @param {DragEvent} e */ e => {
        e.dataTransfer.setData("text/plain", id);
        setPositioning(true);
    };
}

/**
 * @param {(boolean) => void} setPositioning 
 */
function getHandleDragEnd(setPositioning) {
    return () => {
        setPositioning(false);
    };
} 

/**
 * @type {React.Context<{
 *  state: import("shared/definitions/questions/sort.defs.js").State
 *  stateInfo: import("shared/definitions/questions/sort.defs.js").StateInfo
 *  sendMessage: (Object) => void
 *  activeTeamView: number
 *  initializing: boolean
 * }>}
 */
const Question_Sort_Context = createContext({
   state: null,
   stateInfo: null,
   sendMessage: null,
   activeTeamView: -1,
   initializing: true
});

/**
 * @type {React.Context<{
 *  positioning: boolean
 *  setPositioning: (boolean) => void
 * }>}
 */
const Question_Sort_Positioning_Context = createContext({
    positioning: false,
    setPositioning: () => null
});

/**
 * @param {Object} props 
 * @param {import("shared/definitions/questions/sort.defs.js").State} props.state
 * @param {import("shared/definitions/questions/sort.defs.js").StateInfo} props.activeTeamView
 * @param {number} props.stateInfo
 * @param {(Object) => void} props.sendMessage
 * @returns {React.JSX.Element}
 */
function CQuestionSort({state, stateInfo, activeTeamView, sendMessage}) {
    const [initializing, setInitializing] = useState(true);

    useEffect(() => {
        setInitializing(false);
    }, []);

    return (    
        <Question_Sort_Context.Provider value={{
                    state: state,
                    stateInfo: stateInfo,
                    sendMessage: sendMessage,
                    activeTeamView: activeTeamView,
                    initializing: initializing
                }} >
            <CQuestionSortContent />
            <CQuestionSortControls />
        </Question_Sort_Context.Provider>
    );
}

/**
 * @param {Object} props 
 * @returns {React.JSX.Element}
 */
function CQuestionSortContent({}) {
    const context = useContext(Question_Sort_Context);

    const [positioning, setPositioning] = useState(false);
    
    let classes = "question_sort_content";
    if (context.state.phase === PHASE.START) classes += " invisible";

    return (
        <Question_Sort_Positioning_Context.Provider value={{
                    positioning: positioning,
                    setPositioning: setPositioning
                }}>
            <div className={classes}>
                <CAnswers />
                <div className="column_right">
                    <CElements />
                    <CNotesList />
                </div>
            </div>
        </Question_Sort_Positioning_Context.Provider>
    );
}

/**
 * @param {Object} props 
 * @returns {React.JSX.Element}
 */
function CQuestionSortControls({}) {
    const context = useContext(Question_Sort_Context);
    
    const phases = useRef(new Map([
        [PHASE.START, getLanguageText("phase_start")],
        [PHASE.QUESTION, getLanguageText("phase_question")],
        [PHASE.PREPARATION, getLanguageText("phase_preparation")],
        [PHASE.PREPARATION_END, getLanguageText("phase_preparationend")],
        [PHASE.ANSWERS, getLanguageText("phase_answers")],
        [PHASE.RESULTS, getLanguageText("phase_results")],
        [PHASE.FINISHED, getLanguageText("phase_finished")]
    ]));
    
    if (!context.stateInfo.role.isAdmin) return null;

    const handleNextPhase = () => context.sendMessage({
        type: MESSAGE.NEXT_PHASE,
        next_phase: context.state.phase + 1
    });
    const handleShowNextElement = () => {
        let element = context.state.elements.find(e => !e.visible && !e.position_visible);
        if (!element) element = context.state.elements.find(e => !e.visible);
        if (!element) return;
        context.sendMessage({
            type: MESSAGE.SHOW_ELEMENT,
            element_id: element.id
        });
    };
    const handleShowAllElements = () => context.sendMessage({
        type: MESSAGE.SHOW_ELEMENTS
    });
    const handleEvaluateAnswer = () => context.sendMessage({
        type: MESSAGE.EVALUATE_ANSWER
    });
    const handleAnswerTooLate = () => context.sendMessage({
        type: MESSAGE.ANSWER_TOO_LATE
    });
    const handleRevertAnswer = () => context.sendMessage({
        type: MESSAGE.REVERT_ANSWER
    });
    const handleOrderElements = () => context.sendMessage({
        type: MESSAGE.ORDER_ELEMENTS
    });
    const handleShowValues = () => context.sendMessage({
        type: MESSAGE.SHOW_VALUES
    });
    const handleShowInfos = () => context.sendMessage({
        type: MESSAGE.SHOW_INFOS
    });

    const showElementsVisible = context.state.phase === PHASE.QUESTION && !context.state.elements.every(e => e.visible);
    const answerHandlingVisible = context.state.phase === PHASE.ANSWERS && context.state.answer;

    return (
        <CQuestionContentControls>
            <CQuestionContentControlsPhaseButton 
                key="buttonNextPhase"
                phases={phases.current} 
                currentPhase={context.state.phase} 
                onNextPhase={handleNextPhase}
            />
            <CQuestionContentControlsButton 
                key="buttonShowNextElement"
                visible={showElementsVisible}
                onClick={handleShowNextElement}
                children={getLanguageText("button_show_next_element")}
            />
            <CQuestionContentControlsButton 
                key="buttonShowAllElements"
                visible={showElementsVisible}
                onClick={handleShowAllElements}
                children={getLanguageText("button_show_all_elements")}
            />
            <CQuestionContentControlsButton 
                key="buttonEvaluateAnswer"
                visible={answerHandlingVisible}
                onClick={handleEvaluateAnswer}
                children={getLanguageText("button_evaluate_answer")}
            />
            <CQuestionContentControlsButton 
                key="buttonAnswerTooLate"
                visible={answerHandlingVisible}
                onClick={handleAnswerTooLate}
                children={getLanguageText("button_answer_too_late")}
            />
            <CQuestionContentControlsButton 
                key="buttonRevertAnswer"
                visible={answerHandlingVisible}
                onClick={handleRevertAnswer}
                children={getLanguageText("button_revert_answer")}
            />
            <CQuestionContentControlsButton 
                key="buttonOrderElements"
                visible={context.state.phase === PHASE.RESULTS && context.state.elements.some(e => !e.position_visible)}
                onClick={handleOrderElements}
                children={getLanguageText("button_order_elements")}
            />
            <CQuestionContentControlsButton 
                key="buttonShowValues"
                visible={context.state.phase === PHASE.RESULTS && context.state.elements.some(e => !e.value_visible)}
                onClick={handleShowValues}
                children={getLanguageText("button_show_values")}
            />
            <CQuestionContentControlsButton 
                key="buttonShowInfos"
                visible={context.state.phase === PHASE.RESULTS && context.state.elements.some(e => !e.value_visible)}
                onClick={handleShowInfos}
                children={getLanguageText("button_show_infos")}
            />
        </CQuestionContentControls>
    );
}

/**
 * @param {Object} props 
 * @returns {React.JSX.Element}
 */
function CAnswers({}) {
    const context = useContext(Question_Sort_Context);

    let answers = context.state.order.map(id => context.state.elements.find(e => e.id === id)).filter(e => e.position_visible);
    if (!context.stateInfo.role.isAdmin) answers = answers.filter(a => a.visible);
    if (context.state.answer)
        answers.splice(context.state.answer.position, 0, context.state.elements.find(e => e.id === context.state.answer.element_id));
    const answerElements = answers.map((a,i) => {
        const ref = createRef(null);

        let answerState = null;
        if (context.stateInfo.role.isAdmin && a.id === context.state.answer?.element_id) {
            answerState = "correct";
            const answerPosition = context.state.answer.position;
            const answerOrder = context.state.order.findIndex(o => o === context.state.answer.element_id);
            const orderedElements = context.state.elements.filter(e => e.position_visible);
            if (answerPosition > 0) {
                const prevElementOrder = context.state.order.findIndex(o => o === orderedElements[answerPosition-1].id);
                if (prevElementOrder > answerOrder) answerState = "wrong";
            }
            if (answerPosition < orderedElements.length - 1) {
                const nextElementOrder = context.state.order.findIndex(o => o === orderedElements[answerPosition+1].id);
                if (nextElementOrder < answerOrder) answerState = "wrong";
            }
        }

        return {
            jsx: (<>
                    <CAnswer ref={ref} answer={a} number={i+1} answerState={answerState} />
                </>),
            ref: ref,
            key: a.id
        };
    });

    const handleDropFirst = /** @param {DragEvent} e */ e => context.sendMessage({
        type: MESSAGE.ANSWER,
        element_id: Number.parseInt(e.dataTransfer.getData("text/plain")),
        position: 0
    });

    return (
        <div className="answers">
            <div className="answers_grid">
                <CLimitTop />
                <CDropGap onDrop={handleDropFirst} />
                <CExitList elements={answerElements} endType="transitionend" />
                <CLimitBottom />
            </div>
            <div className="counter">
                {context.state.elements.filter(e => e.position_visible).length + " / " + context.state.elements.length}
            </div>
        </div>
    );
}

const CAnswer = forwardRef(
/**
 * @param {Object} props 
 * @param {import("shared/definitions/questions/sort.defs.js").State_Element} props.answer 
 * @param {number} props.number 
 * @param {"correct" | "wrong"} [props.answerState] 
 * @param {React.MutableRefObject} ref
 * @returns {React.JSX.Element}
 */
function CAnswer({answer, number, answerState=null}, ref) {
    const context = useContext(Question_Sort_Context);

    const createSpace = useRef(!context.initializing);

    const [correctAnswer, setCorrectAnswer] = useState(false);

    let classes = "answer";

    if (!answer.visible) classes += " not_visible";
    if (createSpace) classes += " create_space";

    const currentAnswer = answer.id === context.state.answer?.element_id;
    if (currentAnswer) classes += " current_answer";

    useEffect(() => {
        if (context.initializing || currentAnswer || !answer.position_visible) return;
        setCorrectAnswer(true);
        const timeout = setTimeout(() => setCorrectAnswer(false), TIME_CORRECT_ANSWER);
        return () => clearTimeout(timeout);
    }, [currentAnswer]);

    if (correctAnswer || answerState === "correct") classes += " correct_answer";
    if (answerState === "wrong") classes += " wrong_answer";

    const handleShowElement = () => context.sendMessage({
        type: MESSAGE.SHOW_ELEMENT,
        element_id: answer.id
    });
    const showButton = context.stateInfo.role.isAdmin && !answer.visible ? (
        <CControlButtonMini
            key="buttonShowElement"
            text="👁"
            square={true}
            onClick={handleShowElement} 
        />
    ) : null;

    const handleDrop = /** @param {DragEvent} e */ e => context.sendMessage({
        type: MESSAGE.ANSWER,
        element_id: Number.parseInt(e.dataTransfer.getData("text/plain")),
        position: number
    });

    return (<>
        <div ref={ref} className={classes}>
            <div className="answer_inner">
                <CNumber number={number} />
                <CElementContent element={answer} controlElements={
                    showButton
                } />
                <CValue id={answer.id} value={answer.value} visible={answer.value_visible} />
            </div>
        </div>
        <CDropGap onDrop={handleDrop} />
    </>);
}
);

/**
 * @param {Object} props 
 * @returns {React.JSX.Element}
 */
function CElements({}) {
    const context = useContext(Question_Sort_Context);

    const ownRef = useRef(null);

    useEffect(() => {
        if (context.activeTeamView < 0) {
            ownRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
    }, [context.activeTeamView]);

    if (context.stateInfo.role.team_id !== undefined) return null; // display notes instead

    let elements = context.state.elements.filter(e => !e.position_visible);
    if (!context.stateInfo.role.isAdmin) elements = elements.filter(e => e.visible);
    const exitListElements = elements.map((a,i) => {
        const ref = createRef(null);
        return {
            jsx: (<CElement ref={ref} element={a} number={i+1} />),
            ref: ref,
            key: a.id
        };
    });

    return (
        <div ref={ownRef} className="elements">
            <div className="elements_grid">
                <CExitList elements={exitListElements} endType="transitionend" />
            </div>
        </div>
    );
}

const CElement = forwardRef(
/**
 * @param {Object} props 
 * @param {import("shared/definitions/questions/sort.defs.js").State_Element} props.element 
 * @param {React.MutableRefObject} ref
 * @returns {React.JSX.Element}
 */
function CElement({element}, ref) {
    const context = useContext(Question_Sort_Context);
    const setPositioning = useContext(Question_Sort_Positioning_Context).setPositioning;

    const createSpace = useRef(!context.initializing);

    let classes = "element";

    if (!element.visible) classes += " not_visible";
    if (createSpace) classes += " create_space";

    const dragable = context.stateInfo.role.isAdmin && context.state.phase === PHASE.ANSWERS;
    if (dragable) classes += " dragable";

    const handleDragStart = dragable ? getHandleDragStart(element.id, setPositioning) : null;
    const handleDragEnd = dragable ? getHandleDragEnd(setPositioning) : null;
    
    const handleShowElement = () => context.sendMessage({
        type: MESSAGE.SHOW_ELEMENT,
        element_id: element.id
    });
    const showButton = context.stateInfo.role.isAdmin && !element.visible ? (
        <CControlButtonMini
            key="buttonShowElement"
            text="👁"
            square={true}
            onClick={handleShowElement} 
        />
    ) : null;

    const handleOrderElement = () => context.sendMessage({
        type: MESSAGE.ORDER_ELEMENT,
        element_id: element.id
    });
    const orderButton = context.stateInfo.role.isAdmin && context.state.phase === PHASE.RESULTS ? (
        <CControlButtonMini
            key="buttonOrderElement"
            text="👁"
            square={true}
            onClick={handleOrderElement} 
        />
    ) : null;

    return (
        <div ref={ref} className={classes} draggable={dragable} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <div className="element_inner">
                <CElementContent element={element} leftInnerEdge={false} controlElements={<>
                    {showButton}
                    {orderButton}
                </>} />
                <CValue id={element.id} visible={element.value_visible} value={element.value} />
            </div>
        </div>
    );
}
);

/**
 * @param {Object} props 
 * @returns {React.JSX.Element}
 */
function CNotesList({}) {
    const context = useContext(Question_Sort_Context);

    const children = context.state.teams.map(t => {
        return (
            <CNotes key={t.id} teamId={t.id} notes={t.notes} />
        );
    });

    return (<>
        {children}
    </>);
}

/**
 * @param {Object} props 
 * @param {number} props.teamId 
 * @param {import("shared/definitions/questions/sort.defs.js").State_Note[]} props.notes 
 * @returns {React.JSX.Element}
 */
function CNotes({teamId, notes}) {
    const context = useContext(Question_Sort_Context);
    const ownRef = useRef(null);
    const keyGenerator = useRef(new NoteKeyGenerator());

    const [currentNotes, setCurrentNotes] = useState(notes);
    const [[oldMovedNote, oldDirection], setOldMoved] = useState([undefined, undefined]);

    useEffect(() => {
        if (context.activeTeamView === teamId) {
            ownRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
    }, [context.activeTeamView]);

    const [movedNote, direction] = useTrackMovedNote(notes);

    useEffect(() => {
        if (!movedNote) {
            setCurrentNotes(notes);
            return;
        }
        setCurrentNotes(notes.filter(n => n.element_id !== movedNote.element_id));
        setTimeout(() => {
            setCurrentNotes(notes);
            setOldMoved([movedNote, direction]);
        });
    }, [notes]);

    useEffect(() => {
        setOldMoved([undefined, undefined]);
    }, [oldDirection]);

    const noteElements = currentNotes.map((n, i) => {
        const element = context.state.elements.find(e => e.id === n.element_id);
        const noteElement = {
            ...element,
            ...n
        }

        const wrong = isWrongNotePosition(currentNotes, noteElement, context.state.order);

        const moved = movedNote?.element_id === element.id ? direction : 
            oldMovedNote?.element_id === element.id ? oldDirection : undefined;
        if (moved && oldDirection) keyGenerator.current.newKey(element.id);

        const ref = createRef(null);
        const key = keyGenerator.current.getKey(element.id);
        return {
            jsx: (<>
                    <CNote ref={ref} key={key} number={i} noteElement={noteElement} numberElements={currentNotes.length} moved={moved} wrong={wrong} />
                </>),
            ref: ref,
            key: key
        };
    });

    const handleDropFirst = /** @param {DragEvent} e */ e => context.sendMessage({
        type: MESSAGE.NOTE_ORDER,
        element_id: Number.parseInt(e.dataTransfer.getData("text/plain")),
        position: 0,
        number_elements: currentNotes.length
    });

    return (
        <div ref={ownRef} className="notes">
            <div className="notes_grid">
                <CLimitTop />
                <CDropGap onDrop={handleDropFirst} />
                <CExitList elements={noteElements} endType="transitionend" />
                <CLimitBottom />
            </div>
        </div>
    );
}

/**
 * @param {import("shared/definitions/questions/sort.defs.js").State_Note[]} notes 
 * @param {import("shared/definitions/questions/sort.defs.js").State_Element & import("shared/definitions/questions/sort.defs.js").State_Note} noteElement 
 * @param {number[]} order 
 */
function isWrongNotePosition(notes, noteElement, order) {
    if (!noteElement.position_visible) return false;
    if (noteElement.fixed) return false;

    const filterNotes = notes.filter(n => n.fixed || n.element_id === noteElement.id);
    const ownIndex = filterNotes.findIndex(n => n.element_id === noteElement.id);
    const ownOrder = order.findIndex(o => o === noteElement.id);

    if (ownIndex !== 0 
        && order.findIndex(o => o === filterNotes[ownIndex-1].element_id) > ownOrder) 
            return true;
    if (ownIndex !== filterNotes.length - 1 
        && order.findIndex(o => o === filterNotes[ownIndex+1].element_id) < ownOrder) 
            return true;

    return false;
}

class NoteKeyGenerator {

    /**
     * @type {Map<number,number>}
     */
    keys;

    constructor() {
        this.keys = new Map();
    }

    /**
     * @param {number} noteId 
     * @returns {string}
     */
    getKey(noteId) {
        const counter = this.keys.get(noteId);
        if (counter === undefined) return noteId;
        return noteId + "_" + counter;
    }

    /**
     * @param {number} noteId 
     */
    newKey(noteId) {
        const counter = this.keys.get(noteId);
        if (counter === undefined) {
            this.keys.set(noteId, 1);
        } else {
            this.keys.set(noteId, counter + 1);
        }
    }
}

/**
 * @param {import("shared/definitions/questions/sort.defs.js").State_Note[]} notes 
 * @return {[(import("shared/definitions/questions/sort.defs.js").State_Note | undefined, "down" | "up" | undefined)]}
 */
function useTrackMovedNote(notes) {
    const [oldNotes, setOldNotes] = useState(notes);

    useEffect(() => {
        setOldNotes(notes);
    }, [notes]);

    return useCallback(getMovedNote, [notes, oldNotes])(oldNotes, notes);
}

/**
 * @param {import("shared/definitions/questions/sort.defs.js").State_Note[]} oldNotes 
 * @param {import("shared/definitions/questions/sort.defs.js").State_Note[]} newNotes 
 * @return {[(import("shared/definitions/questions/sort.defs.js").State_Note | undefined, "down" | "up" | undefined)]}
 */
function getMovedNote(oldNotes, newNotes) {
    const oldIndices = new Map();
    oldNotes.forEach((note, index) => {
        oldIndices.set(note.element_id, index);
    });

    const movedNoteIndex = newNotes.findIndex((note, newIndex) => {
        if (note.fixed) return false;
        const oldIndex = oldIndices.get(note.element_id);
        if (oldIndex === undefined) return false;
        let diffs = 0;
        const oldPrev = oldIndex > 0 ? oldNotes[oldIndex-1] : undefined;
        const newPrev = newIndex > 0 ? newNotes[newIndex-1] : undefined;
        if (oldPrev?.element_id !== newPrev?.element_id) diffs++;

        const oldNext = oldIndex <= oldNotes.length-1 ? oldNotes[oldIndex+1] : undefined;
        const newNext = newIndex <= newNotes.length-1 ? newNotes[newIndex+1] : undefined;
        if (oldNext?.element_id !== newNext?.element_id) diffs++;

        return diffs === 2;
    });

    if (movedNoteIndex === -1) return [undefined, undefined];

    const movedNote = newNotes[movedNoteIndex];
    const direction = oldIndices.get(movedNote.element_id) < movedNoteIndex ? "down" : "up"

    return [movedNote, direction];
}

const CNote = forwardRef(
/**
 * @param {Object} props 
 * @param {import("shared/definitions/questions/sort.defs.js").State_Element & import("shared/definitions/questions/sort.defs.js").State_Note} props.noteElement 
 * @param {number} props.number  
 * @param {number} props.numberElements 
 * @param {"up" | "down"} [props.moved]
 * @param {boolean} props.wrong 
 * @param {React.MutableRefObject} ref 
 * @returns {React.JSX.Element}
 */
function CNote({noteElement, number, numberElements, moved, wrong}, ref) {
    const context = useContext(Question_Sort_Context);
    const setPositioning = useContext(Question_Sort_Positioning_Context).setPositioning;
    const [localMoved, setLocalMoved] = useState(null);

    const [createSpace, setCreateSpace] = useState(!context.initializing && !moved);

    useEffect(() => {
        if (!moved) return;
        setCreateSpace(false);
        setLocalMoved(moved);
        const handleEvent = (e) => {
            if (e.target !== e.currentTarget) return;
            setLocalMoved(null);
        };
        ref.current.addEventListener("animationend", handleEvent);
        return () => ref.current?.removeEventListener("animationend", handleEvent);
    }, [moved]);

    let classes = "note";

    if (noteElement.suggested) classes += " suggested";

    const [changed, setChanged] = useState(false);

    if (changed) classes += " changed";

    if (localMoved) classes += " moved moved_" + localMoved;

    if (createSpace) classes += " create_space";

    if (noteElement.fixed) classes += " correct";

    if (wrong) classes += " wrong";

    const dragable = context.stateInfo.role.team_id !== undefined && !noteElement.fixed;
    if (dragable) classes += " dragable";

    const handleDragStart = dragable ? getHandleDragStart(noteElement.id, setPositioning) : null;
    const handleDragEnd = dragable ? getHandleDragEnd(setPositioning) : null;

    useEffect(() => {
        if (context.initializing) return; // TODO first render catch
        if (moved) return;
        setChanged(true);
        const timeout = setTimeout(() => setChanged(false), TIME_NOTE_CHANGED);
        return () => clearTimeout(timeout);
    }, [noteElement.text]);

    if (!noteElement.visible) return null;

    const handleDrop = /** @param {DragEvent} e */ e => context.sendMessage({
        type: MESSAGE.NOTE_ORDER,
        element_id: Number.parseInt(e.dataTransfer.getData("text/plain")),
        position: number+1,
        number_elements: numberElements
    });

    const handleClick = !noteElement.position_visible ? () => context.sendMessage({
        type: MESSAGE.NOTE_SUGGEST,
        element_id: noteElement.id
    }) : null;

    return (<>
        <div ref={ref} className={classes} draggable={dragable} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <div className="element_inner">
                <CNumber id={noteElement.id} number={number+1} onClick={handleClick} />
                <CElementContent element={noteElement} />
                <CTag id={noteElement.id} tag={noteElement.tag} />
                <CText id={noteElement.id} text={noteElement.text} />
            </div>
        </div>
        <CDropGap onDrop={handleDrop} />
    </>);
}
);

/**
 * @param {Object} props 
 * @param {number} props.number 
 * @param {() => any} props.onClick 
 * @returns {React.JSX.Element}
 */
function CNumber({number, onClick=null}) {
    let classes = "number";
    if (onClick !== null) classes += " clickable";
    return (
        <div className={classes} onClick={onClick}>
            <CHexagonBox>
                <span>{number}</span>
            </CHexagonBox>
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {import("shared/definitions/questions/sort.defs.js").State_Element} props.element
 * @param {boolean} [props.leftInnerEdge]
 * @param {React.JSX.Element} [props.controlElements]
 * @returns {React.JSX.Element}
 */
function CElementContent({element, leftInnerEdge=true, controlElements}) {
    const context = useContext(Question_Sort_Context);

    // TODO image click

    return (
        <div className="element_content">
            <CHexagonBox leftInnerEdge={leftInnerEdge} info={element.info}>
                <span style={{minWidth: context.state.elements_max_name_length + "em"}}>{element.name}</span>
                {element.img_url && <img sizes="2.2em" src={element.img_url} onClick={null} />}
                {controlElements !== undefined && <div className="controls">
                    {controlElements}
                </div>}
            </CHexagonBox>
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.id
 * @param {string} [props.value] 
 * @param {boolean} props.visible
 * @returns {React.JSX.Element}
 */
function CValue({id, value, visible}) {
    const context = useContext(Question_Sort_Context);
    if (!value) return null;

    let classes = "value";

    if (!visible) classes += " not_visible";

    const controls = context.stateInfo.role.isAdmin 
        && context.state.phase === PHASE.RESULTS 
        && !visible;

    const handleShowValue = () => context.sendMessage({
        type: MESSAGE.SHOW_VALUE,
        element_id: id
    });

    return (
        <div className={classes}>
            <CHexagonBox leftInnerEdge={true}>
                <span style={{minWidth: context.state.elements_max_value_length + "em"}}>{value}</span>
                {controls && <div className="controls">
                    <CControlButtonMini
                        key="buttonShowValue"
                        text="👁"
                        square={true}
                        onClick={handleShowValue} 
                    />
                </div>}
            </CHexagonBox>
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.id
 * @param {TAG} props.tag 
 * @returns {React.JSX.Element}
 */
function CTag({id, tag}) {
    const context = useContext(Question_Sort_Context);
    
    const text = TAG_TEXT_LOOKUP.get(tag);
    const classes = "tag" + TAG_CLASS_LOOKUP.get(tag);
    
    const handleSelectTag = (tag) => context.sendMessage({
        type: MESSAGE.NOTE_TAG,
        element_id: id,
        tag: tag
    });

    let selectors = [];
    for (let entry of TAG_TEXT_LOOKUP.entries()) {
        if (tag === entry[0]) continue;
        selectors.push({text: entry[1] === "" ? "_" : entry[1], onClick: () => handleSelectTag(entry[0])});
    }

    const showSelectors = context.stateInfo.role.team_id !== undefined;

    return (
        <div className={classes}>
            <CHexagonBox leftInnerEdge={true} bottomChildren={
                showSelectors && <CHoverSelectorList selectors={selectors} fadeDown={true} />
            }>
                <span>{text}</span>
            </CHexagonBox>
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.id 
 * @param {string} props.text 
 * @returns {React.JSX.Element}
 */
function CText({id, text}) {
    const context = useContext(Question_Sort_Context);

    const [value, setValue] = useState(text);
    useEffect(() => setValue(text), [text]);
    
    /**
     * @param {KeyboardEvent} e 
     */
    const handleKeyDown = e => {
        if (e.key === "Escape") {
            e.currentTarget.blur();
            setValue(text);
            return;
        }
        if (e.key === "Enter") {
            sendValue();
            return;
        }
    }

    const sendValue = () => context.sendMessage({
        type: MESSAGE.NOTE_TEXT,
        element_id: id,
        text: value
    });

    const showInput = context.stateInfo.role.team_id !== undefined;

    return (
        <div className="text">
            <CHexagonBox leftInnerEdge={true}>
                {showInput && <input 
                    type="text" 
                    maxLength={SETTINGS.NOTE_TEXT_LENGTH} 
                    value={value} 
                    className={"editable"} 
                    onKeyDown={handleKeyDown} 
                    onChange={e => setValue(e.currentTarget.value)} 
                    onBlur={sendValue}
                />}
                <span>{value}</span> 
            </CHexagonBox>
        </div>
    );
}

/**
 * @param {Object} props 
 * @returns {React.JSX.Element}
 */
function CLimitTop({}) {
    const context = useContext(Question_Sort_Context);
    if (!context.state.order_top) return null;
    return (
        <div className="limit limit_top">
            {context.state.order_top}
        </div>
    );
}

/**
 * @param {Object} props 
 * @returns {React.JSX.Element}
 */
function CLimitBottom({}) {
    const context = useContext(Question_Sort_Context);
    if (!context.state.order_bottom) return null;
    return (
        <div className="limit limit_bottom">
            {context.state.order_bottom}
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {(DropEvent) => any} [props.onDrop]
 * @returns {React.JSX.Element}
 */
function CDropGap({onDrop=() => {}}) {
    const context = useContext(Question_Sort_Context);
    const positioning = useContext(Question_Sort_Positioning_Context).positioning;

    const createSpace = useRef(!context.initializing);

    const [dragover, setDragover] = useState(false);

    let classes = "gap";
    if (positioning) classes += " positioning";
    if (dragover) classes += " dragover";
    if (createSpace) classes += " create_space";

    const handleDragEnter = () => {
        setDragover(true);
    };
    const handleDragLeave = () => {
        setDragover(false);
    };
    const handleDragOver = e => {
        e.preventDefault();
    };
    const handleDrop = e => {
        setDragover(false);
        onDrop(e);
    };

    return (
        <div 
            className={classes} 
            onDragEnter={handleDragEnter} 
            onDragLeave={handleDragLeave} 
            onDragOver={handleDragOver} 
            onDrop={handleDrop} 
        ></div>
    );
}
