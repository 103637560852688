
export const CLASSID = "question_sort";

export const SETTINGS = {
    NOTE_TEXT_LENGTH: 50,
    DEFAULT_TIMER_PREPARATION: 180,
    DEFAULT_TIMER_ANSWER: 20,
    DEFAULT_ELEMENT_START: -1,
    DEFAULT_START_VALUE_VISIBLE: true,
    DEFAULT_VALUES_VISIBLE: false
};

/**
 * @typedef Config
 * @property {Object[]} elements
 * @property {string} [elements[].name]
 * @property {string} [elements[].img_url]
 * @property {string | number} elements[].value
 * @property {import("../elements/info.defs").Info} elements[].info
 * 
 * @property {string} [order_top]
 * @property {string} [order_bottom]
 * 
 * @property {number} [element_start] element_index, -1 for a random element, defaults to {@link SETTINGS_QUESTION_SORT.DEFAULT_ELEMENT_START}
 * @property {boolean} [element_start_value_visible] defaults to {@link SETTINGS_QUESTION_SORT.DEFAULT_START_VALUE_VISIBLE}
 * @property {boolean} [values_visible] all values visible after the position is revealed, defaults to {@link SETTINGS_QUESTION_SORT.DEFAULT_VALUES_VISIBLE}
 * 
 * @property {number} [timer_preparation] defaults to {@link SETTINGS_QUESTION_SORT.DEFAULT_TIMER_PREPARATION}
 * @property {number} [timer_answer] defaults to {@link SETTINGS_QUESTION_SORT.DEFAULT_TIMER_ANSWER}
 * @property {number} [mistakes] defaults to 1
 */

/**
 * @typedef State
 * @property {PHASE} phase
 * 
 * @property {string} [order_top]
 * @property {string} [order_bottom]
 * 
 * @property {State_Element[]} elements
 * 
 * @property {number} elements_max_name_length
 * @property {number} elements_max_value_length
 * 
 * @property {number[]} order element_ids, only ids with elements.position_visible are visible for all, from low to high
 * 
 * @property {Object} [answer]
 * @property {number} answer.element_id 
 * @property {number} answer.position position in order with only ids with elements.position_visible
 * 
 * @property {number} active_team_id -1 if no team is active, only in {@link PHASE.ANSWERS}
 *
 * @property {Object[]} teams
 * @property {number} teams[].id
 * @property {State_Note[]} teams[].notes
 * 
 * @property {number} moderation_team_id -1 if no team view is active
 */

/**
 * @typedef State_Element
 * @property {number} id
 * @property {boolean} visible if false, only id is visible for all
 * @property {string} [name]
 * @property {string} [img_url]
 * @property {string} [value]
 * @property {boolean} value_visible
 * @property {boolean} position_visible
 * @property {boolean} info_result_visible
 * @property {import("../elements/info.defs").Info} [info]
 */

/**
 * @typedef State_Note
 * @property {number} element_id
 * @property {boolean} fixed true if the position matches the correct order
 * @property {boolean} suggested only one element can be suggested
 * @property {TAG} tag
 * @property {string} text max length {@link SETTINGS_QUESTION_SORT.NOTE_TEXT_LENGTH}
 */

/**
 * @typedef StateInfo
 * @property {Object} role 
 * @property {boolean} role.isAdmin 
 * @property {boolean} role.isModerator 
 * @property {number} [role.team_id] 
 */

/**
 * @enum {number}
 */
export const TAG = {
    NONE: 0,
    SURE: 1,
    UNSURE: 2
};

/**
 * @enum {number}
 */
export const PHASE = {
    START: 1,
    QUESTION: 2,
    PREPARATION: 3,
    PREPARATION_END: 4,
    ANSWERS: 5,
    RESULTS: 6,
    FINISHED: 7
};

/**
 * @enum {number}
 */
export const MESSAGE = {
    /**
     * Starts next Phase (if matches). 
     * Only {@link isAdmin}. 
     * @param {PHASE} next_phase
     */
    NEXT_PHASE: 1,
    /**
     * Shows an element. 
     * Only {@link isAdmin}. 
     * @param {number} element_id
     */
    SHOW_ELEMENT: 2,
    /**
     * Shows all elements. 
     * Only {@link isAdmin}. 
     */
    SHOW_ELEMENTS: 3,
    /**
     * Give an answer. 
     * Only {@link isAdmin} and {@link team_id} if the active team. 
     * @param {number} element_id
     * @param {number} position
     */
    ANSWER: 4,
    /**
     * Revert the given answer. 
     * Only {@link isAdmin}. 
     */
    REVERT_ANSWER: 5,
    /**
     * Evaluate the given answer. 
     * Only {@link isAdmin}. 
     */
    EVALUATE_ANSWER: 6,
    /**
     * Evaluate the given answer as false. 
     * Only {@link isAdmin}. 
     */
    ANSWER_TOO_LATE: 7,
    /**
     * Show the position of an element. 
     * Only {@link isAdmin}. 
     * @param {number} element_id
     */
    ORDER_ELEMENT: 8,
    /**
     * Show the position of all elements. 
     * Only {@link isAdmin}. 
     */
    ORDER_ELEMENTS: 9,
    /**
     * Show the value of an element. 
     * Only {@link isAdmin}. 
     * @param {number} element_id
     */
    SHOW_VALUE: 10,
    /**
     * Show the values of all elements. 
     * Only {@link isAdmin}. 
     */
    SHOW_VALUES: 11,
    /**
     * Show the info of an element. 
     * Only {@link isAdmin}. 
     * @param {number} element_id
     */
    SHOW_INFO: 12,
    /**
     * Show the infos of all elements. 
     * Only {@link isAdmin}.
     */
    SHOW_INFOS: 13,
    /**
     * Set the order of an elements for the own team notes. 
     * Dont do something if number_elements not matching. 
     * Only {@link team_id}. 
     * @param {number} element_id
     * @param {number} position
     * @param {number} number_elements
     */
    NOTE_ORDER: 14,
    /**
     * Set an elements as suggested for the own team notes. 
     * Only {@link team_id}. 
     * @param {number} element_id
     */
    NOTE_SUGGEST: 15,
    /**
     * Set the tag of an elements for the own team notes. 
     * Only {@link team_id}. 
     * @param {number} element_id
     * @param {TAG} tag
     */
    NOTE_TAG: 16,
    /**
     * Set the tag of an elements for the own team notes. 
     * Only {@link team_id}. 
     * @param {number} element_id
     * @param {string} text max length {@link SETTINGS_QUESTION_SORT.NOTE_TEXT_LENGTH}
     */
    NOTE_TEXT: 17,
    /**
     * Change the moderated team. 
     * Only {@link isModerator}. 
     * @param {number} team_id -1 if no team view is active
     */
    MODERATED_TEAM: 18
};
